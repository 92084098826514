import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = ({ location }) => (
  <Layout>
    <Seo location={location} title="Page Not Found" />
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-5 px-4 xl:px-0 sm:py-5">
        <div className="pt-5 pb-4">
          <p className="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            Page Not Found
          </p>
        </div>
        <div>
          <p className="text-lg">Sorry, but the page you were trying to view does not exist.</p>
        </div>
      </div>
    </div>  
  </Layout>
)

export default NotFoundPage
